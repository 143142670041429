.filter-date {
  width: 100%;
  height: 37px;
}
.filter-strip {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
}
.statistics {
  margin-top: 15px;
  border-radius: 10px;
}
.stats-chart {
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 10px;
}
.stats-figures {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.Quick-actions-main {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 15px;
}
.Quick-actions-main h6 {
  text-align: center;
  padding: 10px;
  background-color: #656565;
  border-radius: 10px;
  color: #ffff;
}
.status-dashboard {
  /* border: 2px solid #656565; */
  display: flex;
  justify-content: space-between;
  /* color: #ffff; */
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
}
.status-dashboard h4 {
  font-family: "Josefin-bold";
}
.status-dashboard p {
  margin: 0 !important;
  font-size: 18px !important;
  font-family: "Josefin-semibold";
  color: #7e7874;
}
.at-glance {
  margin-top: 20px;
  text-align: center;
  font-family: "Josefin-bold";
}

.quick-action button {
  width: 100%;
  padding: 20px;
  background-color: #283f68;
  color: #ffff;
  border: none;
  border-radius: 8px;
}
.quick-action button:hover {
  background-color: #656565;
}
.dash-details {
  /* background-color: #ffff; */
  border-radius: 10px;
  margin-top: 15px;
}

.dash-details .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #283f68;
}
.dash-details .nav-link {
  color: black;
}

.Quick-actions-main .action-position {
  padding-top: 10px;
}
.dash-details-sidebar {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}
