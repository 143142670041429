.employees-update button {
  background-color: #283f68;
  color: #ffff;
  border: none;
  border-radius: 10px;
}

.employees-update button:hover {
  background-color: #283f68 !important;
  color: #ffff !important;
}

.store-employees {
  box-shadow: 1px 1px 10px 3px #c5c5c5;
  padding: 5px;
  margin-top: 10px;
  height: 450px;
  overflow-y: auto;
  border-radius: 10px;
}

.store-employees h5 {
  font-weight: 600;
}

.store-employees label {
  background-color: #d0d0d0b9;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.return-policy textarea {
  border: 2px solid #283f68;
}

.update-discount-btn {
  background-color: #283f68 !important;
  color: #ffff !important;
  margin-top: 30px;
}

.receipt-section {
  padding: 7px;
}

.image-policy {
  border: 2px solid #283f68;
  padding: 7px;
  border-radius: 5px;
}

.policy {
  width: 100%;
  box-shadow: 1px 1px 10px 3px #c5c5c5;
  height: 100px;
}

.policy p {
  width: 100%;
}

.store-logo {
  width: 500px;
}

.store-logo img {
  width: 100%;
}
