.proximeet-sidebar {
  background-image: url("../../assets/images/sidebarPattern.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #283f68;
}
.proximeet-sidebar ul li {
  padding-top: 10px;
}
