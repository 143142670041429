.edit-customer-main .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #283f68;
}

.edit-customer-main .nav-link {
  color: black;
}

.edit-customer-form-strips {
  background-color: #656565;
  padding: 15px;
  color: #ffff;
  border-radius: 10px;
}

.notification-save {
  text-align: center;
  background-color: #283f68;
  margin-top: 10px;
  color: #ffff;
  border: none;
  width: 100px;
  height: 50px;
}

.notification-item {
  box-shadow: 0px 1px 10px 1px #a0a0a0;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.notification-items {
  box-shadow: 0px 1px 10px 1px #a0a0a0;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  list-style: none;
}

.notification-list {
  padding: 0 !important;
  margin: 0 !important;
}
